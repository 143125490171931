.project-card{

  background-color:var(--gray5);
  border-radius: 15px;
  margin: 10px 30px;
  width: 260px;
  height: 285px;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
  transition: transform 0.3s linear, background-color 0.5s linear, color 0.5s linear;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  will-change: transform;

  .image{
    position: relative;
    top: 0%;
    left: 50%;
    transform: translate(-50%, 0%);
    width: 100%;
    height: 140px;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    background-size: cover;
    background-position: center bottom 10%;
  }

  .text{
    padding: 10px 10px;
    
    > * {
      padding: 3px 3px;
    }

    h3 {
      font-size: 16px;
      font-weight: 600;
      color: var(--gray1);
      transition: color 0.5s linear;
    }

    .tags{
      display: flex;
      flex-direction: row;
    }

    .description {
      font-size: 12px;
      font-weight: 200;
      text-align: justify;
      text-justify: inter-word;
    }
  }

  &:hover {
    transform: scale(1.05);
  }

}


@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}