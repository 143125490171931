.switch {
  background-color: var(--bg);
  transition: background-color 0.2s linear, color 0.2s linear;
  cursor: pointer;
  width: inherit;
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  input {
    display: none ;
  }

}