@import '../../utils.scss';

.intro{

  background-color: transparent;

  #c{
    width: 100%;
    height: 100%;
    opacity: 1;
  }

  .info{
    z-index: 1;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    left: 50%;
    top: vh(50);
    transform: translate(-50%, -50%);
    width: 100vw;
    height: vh(40);

    h1{
      font-size: 60px;
      font-weight: 600;
    }

    h4{
      font-size: 22px;
      font-weight: 400;
    }
  }

  p{
    font-size: 30px;
    font-weight: 200;
  }

  .arrow-border{
    position: absolute;
    border-radius: 50%;
    top: vh(85);
    font-size: 25px;
    font-weight: 700;
    left: 50%;
    transform: translateX(-50%);
    background: linear-gradient(190deg, var(--gradient1), var(--gradient2));
    padding: 3px;
  }

  .arrow-container{
    
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: var(--bg);
    display: flex;
    align-items: center;
    justify-content: center;

    .arrow{
      //animation: MoveUpDown 1s linear infinite;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--bg);
    }
  }

  @keyframes MoveUpDown {
    0%, 100% {
      transform: translateY(-3px);
    }
    50% {
      transform: translateY(3px);
    }
  }

  $tabletBreakpoint: 991px;

  @media (max-width: $tabletBreakpoint) {

    .info{
        
      h1{
        font-size: 37px;
        font-weight: 600;
      }

      h4{
        font-size: 13px;
        font-weight: 500;
      }
    }

  .arrow-border{
      top: vh(77);
  }

  .arrow-container{
    width: 45px;
    height: 45px;
    font-size: 25px;
  }

    @keyframes MoveLeftRight {
      0% {
        transform: translateX(-2px);
      }
      50% {
        transform: translateX(2px);
      }
      100% {

      }
    }
  }
    
}