.tag {
  margin-right: 10px;
  padding: 2px 5px;
  border-radius: 10px;
  font-size: 12px;
  font-weight: 700;
  border: 2px solid transparent;
  transition: border 0.3s linear, transform 0.1s linear, background-color .5s linear, color .5s linear;

  &.active{
    border: 2px currentColor solid;
    &.clickable:hover{
      transform: translateY(-2px);
    }
  }

  &.inactive{
    border: 2px currentColor transparent;
    &.clickable:hover{
      transform: translateY(-2px);
    }
  }
}